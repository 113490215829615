import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import router from "@/router";

// Actions
export const REGISTER = "register";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const FORGOT = "forgotPassword";
export const RESET_PW = "resetMyPassword";

// Mutations
export const SET_ERROR = "setCurrentError";
export const PURGE_AUTH = "logOut";
export const REFRESH_TOKEN = "refreshUserAccessToken";

/**
 * Authentication State
 * @type {{tabIndex: number, isAuthenticated: boolean, error: null, client_version: string}}
 * @since 2.0.0
 */
const state = {
  error: null,
  tabIndex: 0,
  isAuthenticated: !!JwtService.getToken(),
  client_version:"2.0.23",
};

/**
 * Authentication Getters
 * @type {{currentClientVersion(*): string|string|*, currentUser(*): any}}
 * @since 2.0.0
 */
const getters = {
  currentClientVersion(state) {
    return state.client_version;
  },
  currentError(state) {
    return state.error;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
}

/**
 * Authentication Mutations
 * @type {{"[SET_ERROR]"(*, *): void}}
 * @since 2.0.0
 */
const mutations = {
  /**
   * Set Current Error for View
   * @param state
   * @param error
   * @since 2.0.0
   */
  [SET_ERROR](state, error) {
    state.error = 'Unknown Error';
    if(error.detail !== undefined) {
      state.error = error.detail;
    } else {
      state.error = error;
    }
  },
  /**
   * Logout and remove all session data
   * @param state
   * @since 2.0.0
   */
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
  },

  /**
   * Refresh Access token and reload user data
   * @since 2.0.0
   */
  [REFRESH_TOKEN]() {
    ApiService.post("oauth", {
      "client_id": "vueprod",
      "client_secret": "J3s8bcMFRpnykLvF9DzrAmVf",
      "refresh_token": JwtService.getRefreshToken(),
      "grant_type": "refresh_token"
    }).then(({data}) => {
      JwtService.saveToken(data.access_token);
      JwtService.saveRefreshToken(data.refresh_token);
      ApiService.setHeader();
      ApiService.getAuth("user")
          .then(({data}) => {
            state.user = data
          }).catch(() => {
        // did not work so lets go to login
        router.push({name: "login"});
      });
    }).catch(({response})  => {
      // invalid refresh token
      if(response.data !== undefined) {
        if(response.data.status == 400) {
          router.push({name: "login"});
        }
      }
    });
  },
}

/**
 * Authentication Actions
 * @type {{"[REGISTER]"(*, *=): Promise<unknown>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Create New Account
   * @param context
   * @param credentials
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [REGISTER](context, credentials) {
    state.error = null;
    return new Promise((resolve, reject) => {
      ApiService.post("oauth", {
        client_id: "vue2web",
        client_secret: "PNbeGzyaKrKBETJi9YT7LSC9",
        grant_type: "client_credentials"
      }).then(response => {
        ApiService.post("user", credentials, {
          headers: {
            Authorization: "Bearer " + response.data.access_token
          }
        }).then(({ data }) => {
          resolve(data);
        }).catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
      });
    });
  },
  /**
   * Login to existing Account
   * @param context
   * @param credentials
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOGIN](context, credentials) {
    ApiService.cleanHeader();
    state.error = null;
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials, {})
      .then(({data}) => {
        let userId = data.user_id;
        ApiService.post("oauth", {
          "client_id": "vueprod",
          "client_secret": "J3s8bcMFRpnykLvF9DzrAmVf",
          "username": userId,
          "password": credentials.password,
          "grant_type": "password"
        }, {})
          .then(({data}) => {
            console.log('save token ' + data.access_token);
            JwtService.saveToken(data.access_token);
            JwtService.saveRefreshToken(data.refresh_token);
            state.isAuthenticated = true;
            resolve(data);
          }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Send Password Reset Link
   * @param context
   * @param credentials
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [FORGOT](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("oauth", {
        client_id: "vue2web",
        client_secret: "PNbeGzyaKrKBETJi9YT7LSC9",
        grant_type: "client_credentials"
      }).then(response => {
        //JwtService.saveToken(response.data.access_token);
        ApiService.post("forgotpw", credentials, {
          headers: {
            Authorization: "Bearer " + response.data.access_token
          }
        }).then(({ data }) => {
          resolve(data);
        }).catch(({response}) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
      });
    });
  },
  /**
   * Set New Password for User
   * @param context
   * @param credentials
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [RESET_PW](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("oauth", {
        client_id: "vue2web",
        client_secret: "PNbeGzyaKrKBETJi9YT7LSC9",
        grant_type: "client_credentials"
      }).then(response => {
        //JwtService.saveToken(response.data.access_token);
        ApiService.put("forgotpw", credentials, {
          headers: {
            Authorization: "Bearer " + response.data.access_token
          }
        }).then(({ data }) => {
          resolve(data);
        }).catch(({response}) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
      });
    });
  },
  /**
   * Logout
   * @param context
   * @since 2.0.0
   */
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
}

export default {
  state,
  getters,
  actions,
  mutations
};

