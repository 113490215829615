import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USERBALANCE} from "@/services/store/user.module";

// Actions
export const OFFERWALL_LIST = "offerwallList";
export const OFFERWALL_RATINGS = "loadOfferwallsOpenRatings";
export const OFFERWALL_RATE = "submitOfferwallRating";

/**
 * Offerwall State
 * @type {{offerwalls: *[], openRatings: *[], history: {page: number, items: *[], total_items: number, page_count: number, page_size: number}, message: null, current_wall: {}}}
 * @since 2.0.0
 */
const state = {
  history: {
    items: [],
    total_items: 0,
    page_size: 0,
    page: 1,
    page_count: 1
  },
  openRatings: [],
  offerwalls: [],
  current_wall: {},
  message: null
};

/**
 * Offerwall Getters
 * @type {{getOfferwallList(*): []|*[]|*}}
 * @since 2.0.0
 */
const getters = {
  getOfferwallList(state) {
    return state.offerwalls;
  },
  getOfferwallHistory(state) {
    return state.history;
  },
  getOfferwallOpenRatings(state) {
    return state.openRatings;
  },
};

/**
 * Offerwall Actions
 * @type {{"[OFFERWALL_LIST]"(*, *): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get Offerwall Provider List
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [OFFERWALL_LIST](context, options) {
    state.message = null;
    return new Promise((resolve, reject) => {
      ApiService.getAuth("offerwall?page=" + options.page).then(({data}) => {
        state.offerwalls = data._embedded.offerwall;
        state.history.items = data._embedded.history
        state.history.total_items = data._embedded.total_items;
        state.history.page = Number(data._embedded.page);
        state.history.page_size = data._embedded.page_size;
        state.history.page_count = data._embedded.page_count;

        if (data.message !== undefined) {
          if (data.message.type !== undefined) {
            state.message = data.message;
          }
        }

        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Get List of open Offerwall Ratings
   * @returns {Promise<unknown>}
   */
  [OFFERWALL_RATINGS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("owrating")
      .then(({ data }) => {
        state.openRatings = data.open;
        resolve(data);
      })
      .catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Save Rating for Offerwall
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   */
  [OFFERWALL_RATE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("owrating", payload).then(({ data }) => {
        context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance});
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  actions,
  getters
};