// Actions
import {SET_ERROR} from "@/services/store/auth.module";
import ApiService from "@/services/api.service";
import {UPDATE_USERBALANCE} from "@/services/store/user.module";

export const SHORTLINK_LIST = "shortlinkList";
export const SHORTLINK_INFO = "shortlinkInfo";
export const SHORTLINK_CLAIM = "shortlinkClaim";
export const SHORTLINK_HISTORY = "loadShortlinkHistory";
export const SHORTLINK_RATINGS = "loadShortlinkOpenRatings";
export const SHORTLINK_RATE = "submitShortlinkRating";
export const SHORTLINK_UNHIDE = "shortlinkUnHide";
export const SHORTLINK_HIDE = "shortlinkHide";

/**
 * shortLink State
 * @type {{totalReward: number, shortlinks: *[], provider: string, totalLinks: number, totalDone: number, openRatings: *[], current_link: {name: string, href: string}, history: {page: number, items: *[], total_items: number, page_count: number, page_size: number}}}
 * @since 2.0.0
 */
const state = {
  provider: "",
  current_link: {
    href: "",
    name: "",
    reward: 0
  },
  history: {
    items: [],
    total_items: 0,
    page_size: 0,
    page: 1,
    page_count: 1
  },
  openRatings: [],
  shortlinks: [],
  totalReward: 0,
  totalLinks: 0,
  totalDone: 0,
  totalPercent: 0
};

/**
 * shortLink Getters
 * @type {{shTotalLinks(*): number|number|*, shTotalDone(*): number|number|*, shortlinkList(*): []|*[]|*, currentActiveName(*): string, currentActiveLink(*): string, shortlinkHistory(*): [], shortlinkOpenRatings(*): [], shTotalReward(*): number|number|*}}
 * @since 2.0.0
 */
const getters = {
  shTotalReward(state) {
    return state.totalReward;
  },
  shTotalLinks(state) {
    return state.totalLinks;
  },
  shTotalDone(state) {
    return state.totalDone;
  },
  shTotalPercent(state) {
    return state.totalPercent;
  },
  shortlinkList(state) {
    return state.shortlinks;
  },
  shortlinkHistory(state) {
    return state.history;
  },
  currentActiveLink(state) {
    return state.current_link.href;
  },
  currentActiveName(state) {
    return state.current_link.name;
  },
  currentActiveReward(state) {
    return state.current_link.reward;
  },
  shortlinkOpenRatings(state) {
    return state.openRatings;
  }
};

/**
 * shortLink Actions
 * @type {{"[SHORTLINK_RATINGS]"(): Promise<unknown>, "[SHORTLINK_INFO]"(*, *): Promise<*>, "[SHORTLINK_CLAIM]"(*, *): Promise<*>, "[SHORTLINK_LIST]"(*, *): Promise<*>, "[SHORTLINK_HISTORY]"(*, *): Promise<unknown>, "[SHORTLINK_HIDE]"(*, *): Promise<unknown>, "[SHORTLINK_RATE]"(*, *=): Promise<unknown>, "[SHORTLINK_UNHIDE]"(*, *): Promise<unknown>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get shortLink Provider List
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_LIST](context, options) {
    state.shortlinks = [];
    return new Promise((resolve, reject) => {
      let url = "shortlink?page="+options.page;
      if(options.showall !== undefined) {
        url += "&showall=1";
      }
      ApiService.getAuth(url).then(({ data }) => {
        state.shortlinks = data._embedded.shortlink[0].provider;
        state.totalReward = data._embedded.shortlink[0].total_reward;
        state.totalLinks = data._embedded.shortlink[0].total_links;
        state.totalDone = data._embedded.shortlink[0].links_done;
        state.totalPercent = data._embedded.shortlink[0].links_percent;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load shortLink History
   * @param context
   * @param options
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_HISTORY](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("slhistory?page="+options.page).then(({ data }) => {
        state.history.items = data.history.items;
        state.history.page = Number(data.history.page);
        state.history.total_items = Number(data.history.total_items);
        state.history.page_size = Number(data.history.page_size);
        state.history.page_count = Number(data.history.page_count);
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Get Open shortLink Ratings
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_RATINGS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("slrating").then(({ data }) => {
        state.openRatings = data.open;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Save shortLink Rating
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_RATE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("slrating", payload).then(({ data }) => {
        context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance});
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Get shortLink Info
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_INFO](context, shortlink) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("shortlink/"+shortlink.name).then(({ data }) => {
        state.current_link.href = data.link.href;
        state.current_link.name = data.name;
        state.current_link.reward = data.reward;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Claim Complete shortLink
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_CLAIM](context, shortlink) {
    return new Promise((resolve, reject) => {
      ApiService.put("shortlink/claim", {link_id:shortlink.link_id}).then(({ data }) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Hide Shortlink from List
   * @param context
   * @param shortlink
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_HIDE](context, shortlink) {
    return new Promise((resolve, reject) => {
      ApiService.deleteNoCaptcha("shortlink/"+shortlink.id).then(({ data }) => {
        let shortlinks = [];
        state.shortlinks.forEach(sh => {
          if(sh.id != shortlink.id) {
            shortlinks.push(sh);
          }
        });
        state.shortlinks = shortlinks;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Show Shortlink in List
   * @param context
   * @param shortlink
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SHORTLINK_UNHIDE](context, shortlink) {
    return new Promise((resolve, reject) => {
      ApiService.deleteNoCaptcha("shortlink/"+shortlink.id).then(({ data }) => {
        let shortlinks = [];
        state.shortlinks.forEach(sh => {
          if(sh.id == shortlink.id) {
            sh.hidden = false;
          }
          shortlinks.push(sh);
        });
        state.shortlinks = shortlinks;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  actions,
  getters
};