import ApiService from "@/services/api.service";

// Actions
export const LOAD_FAUCETSTATS = "getFaucetStats";

// Mutations
export const WEB_UPDATE_TAB = "updateActiveWebTab";

/**
 * Web State
 * @type {{offers: number, tab: null, shortlinks: number, withdrawn: number, games: number, claims: number, days: number, hashrate: number, users: number}}
 * @since 2.0.0
 */
const state = {
  tab: null,
  users: 40487,
  hashrate: 0,
  withdrawn: 8660,
  games: 0,
  days: 460,
  shortlinks: 1547418,
  offers: 90695,
  claims: 1149369
};

/**
 * Web Getters
 * @type {{activeWebTab(*): *}}
 * @since 2.0.0
 */
const getters = {
  activeWebTab(state) {
    return state.tab;
  },
  getLandingPageUsers() {
    return state.users;
  },
  getLandingPageHashrate() {
    return state.hashrate
  },
  getLandingPageWithdrawn() {
    return state.withdrawn;
  },
  getLandingPageGames() {
    return state.games;
  },
  getLandingPageDays() {
    return state.days;
  },
  getLandingPageShortlinks() {
    return state.shortlinks;
  },
  getLandingPageOffers() {
    return state.offers
  },
  getLandingPageClaims() {
    return state.claims;
  }
}

/**
 * Web Actions
 * @type {{"[LOAD_FAUCETSTATS]"(): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get Claim Timer
   *
   * @returns {Promise<unknown>}
   * @since 1.0.0
   */
  [LOAD_FAUCETSTATS]() {
    return new Promise(resolve => {
      ApiService.get("faucet-stats").then(({ data }) => {
        state.users = data.users;
        state.hashrate = data.hashrate;
        state.withdrawn = data.withdrawn;
        state.games = data.games;
        state.days = data.days_online;
        state.shortlinks = data.shortlinks;
        state.offers = data.offers;
        state.claims = data.claims;

        resolve(data);
      });
    });
  }
};

/**
 * Web Mutations
 * @type {{"[WEB_UPDATE_TAB]"(*, *): void}}
 * @since 2.0.0
 */
const mutations = {
  [WEB_UPDATE_TAB](state, update) {
    if (update.tab !== undefined) {
      state.tab = update.tab;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};