import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USERBALANCE} from "@/services/store/user.module";

// Actions
export const SEND_DONATION = "sendCoinDonation";
export const GET_DONATION_LIST = "loadDonationIndex";

/**
 * Donate State
 * @type {{list: *[]}}
 * @since 2.0.0
 */
const state = {
  list: [],
  week: 0
};

/**
 * Donate Getters
 * @type {{getDonationPaymentList(*): *[]}}
 * @since 2.0.0
 */
const getters = {
  getDonationPaymentList(state) {
    return state.list;
  },
  getDonationThisWeek(state) {
    return state.week;
  }
};

/**
 * Donate Actions
 * @type {{"[GET_DONATION_LIST]"(*, *): Promise<?>, "[SEND_DONATION]"(*, *): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get FAQ for a certain Category
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GET_DONATION_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("donate?page=" + payload.page).then(({data}) => {
        if(data.donation_week !== undefined) {
          state.week = data.donation_week;
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Get FAQ for a certain Category
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [SEND_DONATION](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("donate", payload).then(({data}) => {
        if(data.token_balance !== undefined) {
          context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance});
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  getters,
  actions
};
