import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USERBALANCE} from "@/services/store/user.module";

// Actions
export const LOAD_WALLETS = "loadWalletsInfo";
export const REQUEST_WITHDRAW = "requestWithdraw";
export const GET_WITHDRAW_HISTORY = "loadWithdrawalHistory";
export const LOAD_USER_WALLETS = "loadUserPublicWallets";

/**
 * Withdraw State
 * @type {{daily_bonus: number, linkedAccs: *[], wallets: *[], my_wallets: *[], daily_base: number, history: {page: number, items: *[], total_items: number, page_count: number, page_size: number}, buffs: *[], message: null, daily_limit: number, nextDate: null, daily_left: number, token_val: number}}
 * @since 2.0.0
 */
const state = {
  wallets: [],
  daily_limit: 0,
  daily_left: 0,
  token_val:0,
  my_wallets: [],
  linkedAccs: [],
  history: {
    items: [],
    total_items: 0,
    page_size: 0,
    page: 1,
    page_count: 1
  },
  buffs: [],
  daily_base: 0,
  daily_bonus: 0,
  nextDate: null,
  message: {}
};

/**
 * Withdraw Getters
 * @type {{getDailyWithdrawLeft(*): number|number|*, getWithdrawBuffs(*): []|*[]|*, getTokenDollarValue(*): number|number|*, getDailyLeftPercent(*): (*|number), getMyWithdrawalHistory(*): {page: number, items: [], total_items: number, page_count: number, page_size: number}, getDailyWithdrawLimit(*): number|number|*, getWithdrawalNextDate(*): null|*, getWithdrawBase(*): number|number|*, getWithdrawalCurrency(*): *, getWithdrawalWallets(*): []|*[]|*, getWithdrawInfoMessage(*): (null|undefined), getWithdrawBonus(*): number|number|*}}
 * @since 2.0.0
 */
const getters = {
  getWithdrawalWallets(state) {
    return state.wallets;
  },
  getDailyWithdrawLimit(state) {
    return state.daily_limit;
  },
  getDailyWithdrawLeft(state) {
    return state.daily_left;
  },
  getTokenDollarValue(state) {
    return state.token_val;
  },
  getDailyLeftPercent(state) {
    if(state.daily_left > 0) {
      return 100-(100/(state.daily_limit/state.daily_left)).toFixed(0);
    } else {
      return 100;
    }
  },
  getWithdrawalCurrency(state) {
    return state.currency;
  },
  getMyWithdrawalHistory(state) {
    return state.history;
  },
  getWithdrawalNextDate(state) {
    return state.nextDate;
  },
  getWithdrawInfoMessage(state) {
    return state.message;
  },
  getWithdrawBuffs(state) {
    return state.buffs;
  },
  getWithdrawBase(state) {
    return state.daily_base;
  },
  getWithdrawBonus(state) {
    return state.daily_bonus;
  },
  getMyPublicWallets(state) {
    return state.my_wallets;
  },
  getMyLinkedAccounts(state) {
    return state.linkedAccs;
  }
};

/**
 * Withdraw Actions
 * @type {{"[GET_WITHDRAW_HISTORY]"(*, *=): Promise<*>, "[LOAD_WALLETS]"(*): Promise<*>, "[REQUEST_WITHDRAW]"(*, *=): Promise<*>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get Withdraws Info
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_WALLETS](context) {
    state.message = null;
    state.buffs = [];
    return new Promise((resolve, reject) => {
      ApiService.getAuth("withdraw").then(({ data }) => {
        state.wallets = data.wallet;
        state.daily_limit = Number(data.daily_limit);
        state.daily_left = Number(data.daily_left);
        state.token_val = data.token_val;
        if(data.message !== undefined) {
          if(data.message.type !== undefined) {
            state.message = data.message;
          }
        }
        if(data.next_payment !== undefined) {
          state.nextDate = data.next_payment;
        }
        if(data.buffs !== undefined) {
          state.buffs = data.buffs;
        }
        if(data.daily_limit_base !== undefined) {
          state.daily_base = data.daily_limit_base;
        }
        if(data.daily_buffs !== undefined) {
          state.daily_bonus = data.daily_buffs;
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Request Crypto Withdrawal
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [REQUEST_WITHDRAW](context, withdrawal) {
    return new Promise((resolve, reject) => {
      ApiService.put("withdraw", withdrawal).then(({ data }) => {
        state.daily_left = Number(data.daily_left);
        context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance, crypto_balance: data.crypto_balance});
        //context.commit(UPDATE_USERWITHDRAWALS, {withdrawals: data.withdrawals});
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load Crypto Withdrawal History
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GET_WITHDRAW_HISTORY](context, withdrawal) {
    return new Promise((resolve, reject) => {
      ApiService.post("withdraw?page="+withdrawal.page, withdrawal).then(({ data }) => {
        state.history = data.history;
        if(data.next_payment !== undefined) {
          state.nextDate = data.next_payment;
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load User Public Wallets
   *
   * @since 1.2.0
   */
  [LOAD_USER_WALLETS](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("userwallets").then(response => {
        state.my_wallets = response.data.wallet;
        state.linkedAccs = response.data.linked;
        resolve(response);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

};

export default {
  state,
  getters,
  actions
};