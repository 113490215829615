import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";

// Actions
export const LOAD_CONTEST_TOPLIST = "loadContestTopList";
export const LOAD_CONTEST_WINNERS = "loadOldContestWinners";

/**
 * Contest State
 * @type {{contestInfo: *[], message: null}}
 * @since 2.0.0
 */
const state = {
  message: null,
  endTime: null,
  contestInfo: []
};

/**
 * Contest Getters
 * @type {{getContestInfoMessage(*): (null|boolean), getContestInfo(*): []|*[]|*}}
 * @since 2.0.0
 */
const getters = {
  getContestInfo(state) {
    return state.contestInfo
  },
  getContestEndTime(state) {
    return state.endTime;
  },
  getContestInfoMessage(state) {
    if(state.message !== null) {
      return state.message;
    } else {
      return false;
    }
  }
};

/**
 * Contest Actions
 * @type {{"[LOAD_CONTEST_WINNERS]"(*, *): Promise<?>, "[LOAD_CONTEST_TOPLIST]"(*, *): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Load Hall of Fame Data
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_CONTEST_WINNERS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("statistics/halloffame?detail=winners&date="+payload.date).then(({ data }) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load Hall of Fame Data
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_CONTEST_TOPLIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("statistics/halloffame?detail=contest").then(({ data }) => {
        if(data.contest !== undefined) {
          state.contestInfo = data.contest;
        }
        if(data.date_end !== undefined) {
          state.endTime = data.date_end;
        }
        if(data.message !== undefined) {
          if(data.message.type !== undefined) {
            state.message = data.message;
          }
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  getters,
  actions
};