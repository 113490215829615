import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USERBALANCE} from "@/services/store/user.module";

// Actions
export const PTC_LISTMYADS = "listMyViewAds";
export const PTC_STARTVIEW = "startPTCView";
export const PTC_COMPLETEVIEW = "completePTCView";

/**
 * PTC State
 * @type {{totalReward: number, ptc_data: {description: string, id: number, title: string, url: string}, totalLinks: number, totalDone: number, page: number, history: {history: *[], page: number, total_items: number, page_count: number}, total_items: number, ptc_view: *[]}}
 * @since 2.0.0
 */
const state = {
  total_items: 0,
  page: 1,
  ptc_data: {
    id: 0,
    title: "",
    description: "",
    url: ""
  },
  history: {
    history: [],
    total_items: 0,
    page_count: 0,
    page: 1
  },
  ptc_view: [],
  totalReward: 0,
  totalLinks: 0,
  totalDone: 0
};

/**
 * PTC Getters
 * @type {{ptcAdData(*): {description: string, id: number, title: string, url: string}|*, ptcTotalReward(*): number, ptcTotalDone(*): number, getPTCHistory(*): {page: number, items: [], total_items: number, page_count: number, page_size: number}, ptcTotalLinks(*): number, ptcList(*): []|*[]|*}}
 * @since 2.0.0
 */
const getters = {
  ptcAdData(state) {
    return state.ptc_data;
  },
  ptcTotalReward(state) {
    return state.totalReward;
  },
  ptcTotalLinks(state) {
    return state.totalLinks;
  },
  ptcTotalDone(state) {
    return state.totalDone;
  },
  ptcList(state) {
    return state.ptc_view;
  },
  getPTCHistory(state) {
    return state.history;
  }
}

/**
 * PTC Actions
 * @type {{"[PTC_LISTMYADS]"(*, *): Promise<*>, "[PTC_STARTVIEW]"(*, *): Promise<*>, "[PTC_COMPLETEVIEW]"(*, *): Promise<*>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get PTC Ads (View)
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_LISTMYADS](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("ptc?page=" + options.page).then(({data}) => {
        state.ptc_view = data.ptc;
        state.totalReward = data.reward_total;
        state.totalLinks = data.total_items;
        state.history.history = data.history.history;
        state.history.total_items = data.history.total_items;
        state.history.page = data.history.page;
        state.history.page_count = data.history.page_count;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Start PTC View
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_STARTVIEW](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("ptc/" + options.id).then(({data}) => {
        state.ptc_data = data.ptc;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Complete PTC View
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_COMPLETEVIEW](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.put("ptc/" + options.id).then(({data}) => {
        state.ptc_data = data.ptc;
        context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance});
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  actions,
  getters
};