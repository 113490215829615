import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "home" */ '@/views/layout/LayoutWeb.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/HomeView.vue'),
      },
      {
        path: '/signup',
        name: 'signup',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/HomeView.vue'),
      },
      {
        path: '/cryptocurrencies',
        name: 'cryptoOverview',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/CryptoView.vue'),
      },
      {
        path: '/offers',
        name: 'offersOverview',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/OffersView.vue'),
      },
      {
        path: '/build-your-community',
        name: 'guildsFeature',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/GuildView.vue'),
      },
      {
        path: '/terms-and-conditions',
        name: 'termsConditions',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/TermsView.vue'),
      },
      {
        path: '/privacy-policy',
        name: 'privacyPolicy',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/PrivacyView.vue'),
      },
      {
        path: '/ads',
        name: 'adSpaces',
        component: () => import(/* webpackChunkName: "home" */ '@/views/web/AdView.vue'),
      },
    ]
  },
  {
    path: "/ref/*",
    name: "refSignup",
    component: () => import("@/views/app/RefRegister.vue")
  },
  {
    path: "/guild-join/*",
    name: "guildSignup",
    component: () => import("@/views/app/GuildRegister.vue")
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "login" */ '@/views/layout/LayoutLogin.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "home" */ '@/views/auth/LoginView.vue'),
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: () => import(/* webpackChunkName: "home" */ '@/views/auth/ForgotView.vue'),
      },
      {
        path: '/reset-pw/*',
        name: 'resetPassword',
        component: () => import(/* webpackChunkName: "home" */ '@/views/auth/ResetView.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/layout/LayoutDashboard.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/app/DashboardView.vue'),
      },
      {
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "news" */ '@/views/app/NewsView.vue'),
      },
      {
        path: '/ptc',
        name: 'ptc',
        component: () => import(/* webpackChunkName: "ptc" */ '@/views/app/PtcView.vue'),
      },
      {
        path: '/community',
        name: 'community',
        component: () => import(/* webpackChunkName: "community" */ '@/views/app/CommunityView.vue'),
      },
      {
        path: '/hall-of-fame',
        name: 'hallOfFame',
        component: () => import(/* webpackChunkName: "hall-of-fame" */ '@/views/app/HallOfFameView.vue'),
      },
      {
        path: '/hall-of-fame/*',
        name: 'hallOfFameDetail',
        component: () => import(/* webpackChunkName: "hall-of-fame" */ '@/views/app/HallDetailView.vue'),
      },
      {
        path: '/token',
        name: 'token',
        component: () => import(/* webpackChunkName: "token" */ '@/views/app/TokenView.vue'),
      },
      {
        path: '/token/my',
        name: 'myToken',
        component: () => import(/* webpackChunkName: "token" */ '@/views/app/token/ListView.vue'),
      },
      {
        path: '/token/statistics',
        name: 'tokenStats',
        component: () => import(/* webpackChunkName: "token" */ '@/views/app/token/StatisticsView.vue'),
      },
      {
        path: '/guild',
        name: 'guilds',
        component: () => import(/* webpackChunkName: "guild-browser" */ '@/views/app/GuildBrowserView.vue'),
      },
      {
        path: '/my-activity',
        name: 'userActivity',
        component: () => import(/* webpackChunkName: "account" */ '@/views/app/ActivityView.vue'),
      },
      {
        path: '/referral',
        name: 'referral',
        component: () => import(/* webpackChunkName: "referral" */ '@/views/app/ReferralView.vue'),
      },
      {
        path: '/inbox',
        name: 'inbox',
        component: () => import(/* webpackChunkName: "inbox" */ '@/views/app/InboxView.vue'),
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/app/SettingsView.vue'),
      },
      {
        path: '/withdraw',
        name: 'withdraw',
        component: () => import(/* webpackChunkName: "withdraw" */ '@/views/app/WithdrawView.vue'),
      },
      {
        path: '/withdraw/history',
        name: 'withdrawHistory',
        component: () => import(/* webpackChunkName: "withdraw" */ '@/views/app/withdraw/HistoryView.vue'),
      },
      {
        path: '/withdraw/info',
        name: 'withdrawLimitInfo',
        component: () => import(/* webpackChunkName: "withdraw" */ '@/views/app/withdraw/InfoView.vue'),
      },
      {
        path: '/withdraw/form/*',
        name: 'withdrawForm',
        component: () => import(/* webpackChunkName: "withdraw" */ '@/views/app/withdraw/FormView.vue'),
      },
      {
        path: '/advertise',
        name: 'advertise',
        component: () => import(/* webpackChunkName: "advertise" */ '@/views/ptc/AdvertiseView.vue'),
      },
      {
        path: "/my-achievements",
        name: "achievements",
        component: () => import(/* webpackChunkName: "achievements" */ '@/views/app/AchievementsView.vue'),
      },
      {
        path: '/wallets',
        name: 'wallets',
        component: () => import(/* webpackChunkName: "wallets" */ '@/views/app/WalletsView.vue'),
      },
      {
        path: '/games/battleships',
        name: 'battleShipGame',
        component: () => import(/* webpackChunkName: "battle-ship-game" */ '@/views/games/battleships/IndexView.vue'),
      },
      {
        path: '/games/battleships/create',
        name: 'createBattleShipGame',
        component: () => import(/* webpackChunkName: "battle-ship-game" */ '@/views/games/battleships/AddForm.vue'),
      },
      {
        path: '/games/battleships/join',
        name: 'joinBattleShipGame',
        component: () => import(/* webpackChunkName: "battle-ship-game" */ '@/views/games/battleships/JoinForm.vue'),
      },
      {
        path: '/games/battleships/play',
        name: 'playBattleShipGame',
        component: () => import(/* webpackChunkName: "battle-ship-game" */ '@/views/games/battleships/PlayView.vue'),
      },
      {
        path: '/games/battleships/view',
        name: 'viewBattleShipGame',
        component: () => import(/* webpackChunkName: "battle-ship-game" */ '@/views/games/battleships/InfoView.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "guild" */ '@/views/layout/LayoutGuild.vue'),
    children: [
      {
        path: '/guild-home',
        name: 'guildHome',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/HomeView.vue'),
      },
      {
        path: '/guild/news',
        name: 'guildNews',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/NewsView.vue'),
      },
      {
        path: '/guild/members',
        name: 'guildMembers',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/MemberView.vue'),
      },
      {
        path: '/guild/bank',
        name: 'guildBank',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/BankView.vue'),
      },
      {
        path: '/guild/activity',
        name: 'guildActivity',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/ActivityView.vue'),
      },
      {
        path: '/guild/weekly-tasks',
        name: 'guildWeeklys',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/WeeklytaskView.vue'),
      },
      {
        path: '/guild/hall-of-fame',
        name: 'guildHallOfFame',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/HallOfFameView.vue'),
      },
      {
        path: '/guild/invites',
        name: 'guildInvites',
        component: () => import(/* webpackChunkName: "guild-admin" */ '@/views/guild/InvitesView.vue'),
      },
      {
        path: '/guild/settings',
        name: 'guildSettings',
        component: () => import(/* webpackChunkName: "guild-admin" */ '@/views/guild/SettingsView.vue'),
      },
      {
        path: '/guild/leave',
        name: 'guildLeave',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/LeaveView.vue'),
      },
      {
        path: '/guild/browse',
        name: 'guildBrowser',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/GuildMemberBrowserView.vue'),
      },
      {
        path: '/guild/news/add',
        name: 'guildAddNews',
        component: () => import(/* webpackChunkName: "guild-admin" */ '@/views/guild/news/AddNewsView.vue'),
      },
      {
        path: '/guild/news/edit/*',
        name: 'guildEditNews',
        component: () => import(/* webpackChunkName: "guild-admin" */ '@/views/guild/news/EditNewsView.vue'),
      },
      {
        path: '/guild/chat',
        name: 'guildChat',
        component: () => import(/* webpackChunkName: "guild" */ '@/views/guild/ChatView.vue'),
      },
    ]
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "ptc" */ '@/views/layout/LayoutPtc.vue'),
    children: [
      {
        path: '/ptc/view/*',
        name: 'ptcWatch',
        component: () => import(/* webpackChunkName: "ptc" */ '@/views/ptc/WatchView.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
