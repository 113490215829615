import {io} from "socket.io-client";

// actions

// mutations
export const RPSSERVER_CONNECT = "openRpsServerConnection";
export const RPSSERVER_DISCONNECT = "closeRpsServerConnection";

/**
 * Chat State
 * @type {{serverConnection: null}}
 * @since 2.0.0
 */
const state = {
  serverConnection: null
};

/**
 * Chat Getters
 * @type {{getChatServerConnection(*): null|Socket<DefaultEventsMap, ListenEvents>|*}}
 * @since 2.0.0
 */
const getters = {
  getRpsServerConnection(state) {
    return state.serverConnection;
  }
};

/**
 * Chat Actions
 * @type {{}}
 * @since 2.0.0
 */
const actions = {
};

/**
 * Chat Mutations
 * @type {{"[CHATSERVER_CONNECT]"(): void}}
 * @since 2.0.0
 */
const mutations = {
  [RPSSERVER_CONNECT]() {
    if(state.serverConnection == null) {
      state.serverConnection = io('https://node.swissfaucet.io:3377');
      //state.serverConnection = io('http://localhost:5353');
    }
  },
  [RPSSERVER_DISCONNECT]() {
    state.serverConnection = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};