import {io} from "socket.io-client";

// actions

// mutations
export const CHATSERVER_CONNECT = "openChatServerConnection";

/**
 * Chat State
 * @type {{serverConnection: null}}
 * @since 2.0.0
 */
const state = {
  serverConnection: null
};

/**
 * Chat Getters
 * @type {{getChatServerConnection(*): null|Socket<DefaultEventsMap, ListenEvents>|*}}
 * @since 2.0.0
 */
const getters = {
  getChatServerConnection(state) {
    return state.serverConnection;
  }
};

/**
 * Chat Actions
 * @type {{}}
 * @since 2.0.0
 */
const actions = {
};

/**
 * Chat Mutations
 * @type {{"[CHATSERVER_CONNECT]"(): void}}
 * @since 2.0.0
 */
const mutations = {
  [CHATSERVER_CONNECT]() {
    if(state.serverConnection == null) {
      state.serverConnection = io('https://node.swissfaucet.io:3344');
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};