import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";

// Actions
export const LOAD_2FA_INFO = "load2FaInfo";
export const CREATE_2FA_KEY = "gen2FaKey";
export const ENABLE_2FA_CODE = "enable2FaCode";
export const DISABLE_2FA_CODE = "disable2FaCode";

/**
 * 2FA State
 * @type {{qrCode: null, key: null, isEnabled: boolean}}
 * @since 2.0.19
 */
const state = {
  key: null,
  qrCode: null,
  isEnabled: false,
};

/**
 * 2FA Getters
 * @type {{get2FAKey(*): null, get2FAQRCode(*): null, get2FAEnabledState(*): boolean}}
 * @since 2.0.19
 */
const getters = {
  get2FAKey(state) {
    return state.key;
  },
  get2FAQRCode(state) {
    return state.qrCode;
  },
  get2FAEnabledState(state) {
    return state.isEnabled;
  }
};

/**
 * 2FA Actions
 * @type {{"[CREATE_2FA_KEY]"(*): Promise<unknown>, "[ENABLE_2FA_CODE]"(*, *): Promise<unknown>, "[LOAD_2FA_INFO]"(*): Promise<unknown>}}
 * @since 2.0.19
 */
const actions = {
  [LOAD_2FA_INFO](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("user/two-factor").then(({ data }) => {
        if(data.tfa_secret !== undefined) {
          state.key = data.tfa_secret;
        }
        if(data.qr_code !== undefined) {
          state.qrCode = data.qr_code;
        }
        if(data.tfa_enabled !== undefined) {
          if(Number(data.tfa_enabled) === 1) {
            state.isEnabled = true;
          }
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  [CREATE_2FA_KEY](context) {
    return new Promise((resolve, reject) => {
      ApiService.post("user/two-factor", {}).then(({ data }) => {
        if(data.tfa_secret !== undefined) {
          state.key = data.tfa_secret;
        }
        if(data.qr_code !== undefined) {
          state.qrCode = data.qr_code;
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  [ENABLE_2FA_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("user/two-factor/enable", payload).then(({ data }) => {
        state.isEnabled = true;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  [DISABLE_2FA_CODE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.deleteNoCaptcha("user/two-factor/" + payload.code).then(({ data }) => {
        state.isEnabled = false;
        state.key = 'disabled';
        state.qrCode = null;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
};

export default {
  state,
  getters,
  actions
};

