import {io} from "socket.io-client";

// actions

// mutations
export const SHIPSSERVER_CONNECT = "openShipsServerConnection";
export const SHIPSSERVER_DISCONNECT = "closeShipsServerConnection";
export const SHIPGAME_NOTIFY = "notifyUserAboutShipProgress";
export const SHIPGAME_ADD_OPENGAME = "addShipGameToOpenList";
export const SHIPGAME_REMOVE_OPENGAME = "removeShipGameFromOpenList";

/**
 * Battleships State
 * @type {{serverConnection: null, openGames: [], notifyGames: []}}
 * @since 2.0.12
 */
const state = {
  serverConnection: null,
  openGames: [],
  notifyGames: []
};

/**
 * Battleships Getters
 * @type {{getChatServerConnection(*): null|Socket<DefaultEventsMap, ListenEvents>|*}}
 * @since 2.0.12
 */
const getters = {
  getShipsServerConnection(state) {
    return state.serverConnection;
  },
  getShipsGameNotifications(state) {
    return state.notifyGames.length;
  }
};

/**
 * Battleships Actions
 * @type {{}}
 * @since 2.0.12
 */
const actions = {
};

/**
 * Battleships Actions
 * @type {{"[CHATSERVER_CONNECT]"(): void}}
 * @since 2.0.12
 */
const mutations = {
  [SHIPSSERVER_CONNECT]() {
    if(state.serverConnection == null) {
      state.serverConnection = io('https://node.swissfaucet.io:3399');
    }
  },
  [SHIPSSERVER_DISCONNECT]() {
    state.serverConnection = null;
  },
  [SHIPGAME_NOTIFY](context, payload) {
    if(payload.match_id !== undefined) {
      if(!state.openGames.includes(payload.match_id)) {
        if(!state.notifyGames.includes(payload.match_id)) {
          state.notifyGames.push(payload.match_id);
        }
      }
    }
  },
  [SHIPGAME_ADD_OPENGAME](context, payload) {
    if(payload.match_id !== undefined) {
      if (!state.openGames.includes(payload.match_id)) {
        state.openGames.push(payload.match_id);
      }
      const index = state.notifyGames.indexOf(payload.match_id);
      if(index > -1) {
        state.notifyGames.splice(index, 1);
      }
    }
  },
  [SHIPGAME_REMOVE_OPENGAME](context, payload) {
    if(payload.match_id !== undefined) {
      const index = state.openGames.indexOf(payload.match_id);
      if(index > -1) {
        state.openGames.splice(index, 1);
      }
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};