import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";

// Actions
export const LOAD_FAQ = "loadFAQFromServer";

/**
 * Support State
 * @type {{faq: *[]}}
 * @since 2.0.0
 */
const state = {
  faq: []
};

/**
 * Support Getters
 * @type {{getPageFAQ(*): []|*[]|*}}
 * @since 2.0.0
 */
const getters = {
  getPageFAQ(state) {
    return state.faq;
  }
};

/**
 * Support Page Actions
 * @type {{"[LOAD_FAQ]"(*, *): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get FAQ for a certain Category
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_FAQ](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("faq?category=" + payload.category).then(({data}) => {
        state.faq = data.faq;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  getters,
  actions
};
