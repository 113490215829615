import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import JwtService from "@/services/jwt.service";

// Actions
export const POST_FEEDBACK = "postFeedback";
export const LIST_FEEDBACK = "listFeedback";
export const LOAD_FEEDBACK = "loadFeedback";
export const CMD_FEEDBACK = "cmdFeedback";

// Mutations
export const UPDATE_FEEDBACK = "updateFeedback";

/**
 * Feedback State
 * @type {{feedback: {items: *[], total_items: number, page_count: number}, detail: {}, token: {vote_comment: number, create: number}}}
 * @since 2.0.0
 */
const state = {
  detail: {},
  feedback: {
    items: [],
    page_count: 1,
    total_items: 1
  },
  token: {
    vote_comment: 0,
    create: 0
  }
};

/**
 *  Feedback Getters
 * @type {{getFeedackDetail(*): {}, getFeedbackList(*): {items: [], total_items: number, page_count: number}, getTokenRequirements(*): {vote_comment: number, create: number}}}
 * @since 2.0.0
 */
const getters = {
  getFeedbackList(state) {
    return state.feedback;
  },
  getFeedackDetail(state) {
    return state.detail;
  },
  getTokenRequirements(state) {
    return state.token;
  }
};

/**
 * Feedback Actions
 * @type {{}}
 * @since 2.0.0
 */
const actions = {
  [POST_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("suggestion", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        Authorization: `Bearer ${JwtService.getToken()}`
      }).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  [LIST_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("suggestion?page=" + payload.page).then(({ data }) => {
        state.feedback.items = data.feedback;
        state.feedback.total_items = data.total_items;
        state.feedback.page_count = data.page_count;
        state.token.vote_comment = data.token.vote_comment;
        state.token.create = data.token.create;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  [LOAD_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("suggestion/" + payload.id).then(({ data }) => {
        state.detail = data.feedback;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  [CMD_FEEDBACK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("suggestion/" + payload.id, payload).then(({data}) => {
        if(data.votes !== undefined) {
          context.commit(UPDATE_FEEDBACK, {votes: data.votes});
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject(response);
      });
    });
  },
};

const mutations = {
  [UPDATE_FEEDBACK](context, payload) {
    if(payload.votes !== undefined) {
      state.detail.votes = payload.votes;
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};