import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";

// Actions
export const LOAD_DASHBOARD = "loadUserDashboardData";

/**
 * Dashboard State
 * @type {{loaded: boolean, charts: {income: {data: *[], max: number, labels: *[]}, tasks: {data: *[], max: number, labels: *[]}}}}
 * @since 2.0.0
 */
const state = {
  loaded: false,
  charts: {
    tasks: {
      labels: [],
      data: [],
      max: 0
    },
    income: {
      labels: [],
      data: [],
      max: 0
    }
  }
};

/**
 * Dashboard Getters
 * @type {{getDashboardWidgetTask(*): {data: [], max: number, labels: []}, getDashboardWidgetIncome(*): {data: [], max: number, labels: []}}}
 * @since 2.0.0
 */
const getters = {
  getDashboardWidgetIncome(state) {
    return state.charts.income;
  },
  getDashboardWidgetTask(state) {
    return state.charts.tasks;
  },
  dashboardDataIsLoaded(state) {
    return state.loaded;
  }
};

/**
 * Dashboard Actions
 * @type {{"[LOAD_DASHBOARD]"(*): Promise<unknown>}}
 * @since 2.0.0
 */
const actions = {
  [LOAD_DASHBOARD](context) {
    return new Promise((resolve, reject) => {
      state.loaded = false;
      ApiService.getAuth("dashboard").then(response => {
        state.loaded = true;
        state.charts.tasks = response.data.chart.task_done_7day;
        state.charts.income = response.data.chart.coins_earned_7day;
        resolve(response);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
};

export default {
  state,
  getters,
  actions
};