import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";

// Actions
export const LOAD_HOFDATA = "loadHOFData";
export const LOAD_HOFDATA_DETAIL = "loadHOFDataDetail";

/**
 * Hall of Fame State
 * @type {{myRankMonth: {}, playerListMonth: *[], playerList: *[], myRank: {}, topGuilds: *[]}}
 * @since 2.0.0
 */
const state = {
  topGuilds: [],
  playerList: [],
  playerListMonth: [],
  myRank: {},
  myRankMonth: {}
};

/**
 * all of Fame Getters
 * @type {{getMyRankAllTime(*): *|{}, getMyRankThisMonth(*): *|{}, getDetailPlayerListMonth(*): []|*[]|*, getTopGuildList(*): []|*[]|*, getDetailPlayerList(*): []|*[]|*}}
 * @since 2.0.0
 */
const getters = {
  getTopGuildList(state) {
    return state.topGuilds;
  },
  getDetailPlayerList(state) {
    return state.playerList;
  },
  getDetailPlayerListMonth(state) {
    return state.playerListMonth;
  },
  getMyRankAllTime(state) {
    return state.myRank;
  },
  getMyRankThisMonth(state) {
    return state.myRankMonth;
  }
};

/**
 * Hall of Fame Actions
 * @type {{"[LOAD_HOFDATA]"(*): Promise<?>, "[LOAD_HOFDATA_DETAIL]"(*, *): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Load Hall of Fame Data
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_HOFDATA](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("statistics/halloffame").then(({data}) => {
        state.topGuilds = data.top_guilds;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load Hall of Fame Data
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_HOFDATA_DETAIL](context, payload) {
    state.playerList = [];
    state.playerListMonth = [];
    state.myRank = {};
    state.myRankMonth = {};
    return new Promise((resolve, reject) => {
      ApiService.getAuth("statistics/halloffame?detail=" + payload.detail).then(({data}) => {
        state.playerList = data.player_list.all;
        state.playerListMonth = data.player_list.month;
        state.myRank = data.me_all;
        state.myRankMonth = data.me_month;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  getters,
  actions
};