import ApiService from "@/services/api.service";
import i18nService from "@/services/i18n.service";
import {SET_ERROR} from "@/services/store/auth.module";

// Actions
export const LOAD_NEWS = "getLatestNews";

/**
 * Blog State
 * @type {{news: *[], page: number, total_items: number, page_count: number}}
 * @since 2.0.0
 */
const state = {
  total_items: 0,
  page_count: 0,
  page: 1,
  news: []
};

/**
 * Blog Getters
 * @type {{recentNewsList(*): []|*[]|*}}
 * @since 2.0.0
 */
const getters = {
  recentNewsList(state) {
    return state.news;
  }
}

/**
 * Blog Actions
 * @type {{"[LOAD_NEWS]"(): Promise<*>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get Recent Blog Articles
   *
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_NEWS](context) {
    return new Promise((resolve, reject) => {
      const lang = i18nService.getActiveLanguage();
      ApiService.getAuth("news?lang="+lang).then(({ data }) => {
        state.total_items = data.total_items;
        state.page = data.page;
        state.page_count = data.page_count;
        state.news = data.news;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
};

export default {
  state,
  actions,
  getters
};