import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {GUILD_UPDATEBALANCE, GUILD_UPDATEINFO} from "@/services/store/guild.module";

// Actions
export const GET_GUILDJOINS = "getGuildJoinRequests";
export const PROCESS_GUILDJOIN = "processGuildJoin";
export const GUILD_UPDATE = "sendGuildUpdate";

export const GUILDRANK_PERMISSIONS = "getGuildRankPermissions";
export const GUILDRANK_SET_PERMISSIONS = "setGuildRankPermissions";
export const GUILDRANK_CREATE = "createNewGuildRank";
export const CHANGE_GUILDRANK = "changeGuildMemberRank";
export const REMOVE_GUILDRANK = "removeGuildMemberRank";

export const REMOVE_GUILDMEMBER = "removeMemberFromGuild";

export const GUILDNEWS_REMOVE = "removeGuildNews";
export const GUILDNEWS_UPDATE = "updateGuildNews";
export const GUILDNEWS_INFO = "loadGuildNewsInfo";

/**
 * Guild Admin State
 * @type {{open_requests: {page: number, requests: *[], total_items: number, page_count: number, page_size: number}}}
 * @since 2.0.0
 */
const state = {
  open_requests: {
    total_items: 0,
    page_count: 0,
    page: 1,
    page_size: 0,
    requests: []
  }
};

/**
 * Guild Admin Getters
 * @type {{getOpenJoinRequestList(*): [], getOpenJoinRequestCount(*): number, getGuildJoinCount(*): number}}
 * @since 2.0.0
 */
const getters = {
  getOpenJoinRequestCount(state) {
    return state.open_requests.total_items;
  },
  getOpenJoinRequestList(state) {
    return state.open_requests.requests;
  }
};

const actions = {
  /**
   * Load List of open Join Requests
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GET_GUILDJOINS](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guild-joins?page=" + guild.page).then(
          ({ data }) => {
            state.open_requests.total_items = data.request.total_items;
            state.open_requests.page_count = data.request.page_count;
            state.open_requests.page = Number(data.request.page);
            state.open_requests.page_size = data.request.page_size;
            state.open_requests.requests = data.request.items;
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Process Join Request Answer
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PROCESS_GUILDJOIN](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("guild-joins?page=" + payload.page, payload).then(
          ({ data }) => {
            state.open_requests.total_items = data.request.total_items;
            state.open_requests.page_count = data.request.page_count;
            state.open_requests.page = Number(data.request.page);
            state.open_requests.page_size = data.request.page_size;
            state.open_requests.requests = data.request.items;
            context.commit(GUILD_UPDATEINFO, {total_requests: data.request.total_items});
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Update Guild Information
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILD_UPDATE](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.put("guild/update", guild).then(({ data }) => {
        context.commit(GUILD_UPDATEINFO, data);
        context.commit(GUILD_UPDATEBALANCE, {token_balance: data.token_balance});
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Get Guild Rank Permissions
   * @param context
   * @param rank
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDRANK_PERMISSIONS](context, rank) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guildrank/" + rank.id).then(
          ({ data }) => {
            //state.guild.rank = data.rank;
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Set Guild Rank Permissions
   * @param context
   * @param rank
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDRANK_SET_PERMISSIONS](context, rank) {
    return new Promise((resolve, reject) => {
      ApiService.put("guildrank/" + rank.id, rank).then(
          ({ data }) => {
            if(data.ranks !== undefined) {
              context.commit(GUILD_UPDATEINFO, {ranks: data.ranks});
            }
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Create new Guild Rank
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDRANK_CREATE](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.post("guildrank", guild).then(({ data }) => {
        if(data.ranks !== undefined) {
          context.commit(GUILD_UPDATEINFO, {ranks: data.ranks});
        }
        if(data.token_balance !== undefined) {
          context.commit(GUILD_UPDATEBALANCE, {token_balance: data.token_balance});
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Change Rank of Guild Member
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [CHANGE_GUILDRANK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("guild-joins?page=" + payload.page, payload).then(
          ({ data }) => {
            context.commit(GUILD_UPDATEINFO, {members: data.items});
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Remove Guild Rank
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [REMOVE_GUILDRANK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService._delete("guildrank/" + payload.id).then(
          ({ data }) => {
            if(data.ranks !== undefined) {
              context.commit(GUILD_UPDATEINFO, {ranks: data.ranks});
            }
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Remove Member from Guild
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [REMOVE_GUILDMEMBER](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService._delete("guild-joins?user_id=" + payload.user_id + '&ban=' + payload.ban).then(() => {
        ApiService.getAuth("guild/" + payload.id + "?page=" + payload.page).then(
            ({ data }) => {
              resolve(data);
            }
        ).catch(({response}) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Remove Guild News Post
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDNEWS_REMOVE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService._delete("guildnews/" + payload.id).then((data) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Remove Guild News Post
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDNEWS_UPDATE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("guildnews/" + payload.id, payload).then((data) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load Single Post from Guild News
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDNEWS_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guildnews/" + payload.id).then((data) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  getters,
  actions
};