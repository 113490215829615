import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#bd0346',
        success: '#1bc5bd',
        info: '#7337ee',
        warning: '#c59701'
      }
    }
  }
});
