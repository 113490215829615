import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USER_XP, UPDATE_USERBALANCE} from "@/services/store/user.module";
import {UPDATE_DAILY_CLAIM_COUNT} from "@/services/store/dailytask.module";

// Actions
export const CLAIM_TIMER = "claimTimer";
export const FAUCET_CLAIM = "faucetClaim";

// Mutations
export const CLAIM_STARTTIMER = "startClaimFaucetTimer";
export const CLAIM_UPDATE_TIMER = "updateClaimFaucetTimer";
export const CLAIM_CLEARTIMER = "clearFaucetClaimTimer";

/**
 * Faucet State
 * @type {{next_claim: number, loaded: boolean, amount: number, quote: {}, countdown: null, message: null, soundList: [{file: string, name: string}, {file: string, name: string}, {file: string, name: string}, {file: string, name: string}, {file: string, name: string}, null, null, null, null, null, null]}}
 * @since 2.0.0
 */
const state = {
  next_claim: 0,
  countdown: null,
  amount: 0,
  loaded: false,
  quote: {},
  showCaptcha: true,
  message: null,
  soundList: [
    {file: 'mario-coin-sound.ogg', name: 'Mario Coin'},
    {file: 'mixkit-clinking-coins-1993.ogg', name: 'Clinking Coins'},
    {file: 'mixkit-coin-win-notification-1992.ogg', name: 'Coin Win Notification'},
    {file: 'mixkit-final-level-bonus-2061.ogg', name: 'Level Bonus'},
    {file: 'mixkit-game-treasure-coin-2038.ogg', name: 'Treasure Coin'},
    {file: 'mixkit-gold-coin-prize-1999.ogg', name: 'Coin Prize'},
    {file: 'mixkit-magical-coin-win-1936.ogg', name: 'Magical Coin Win'},
    {file: 'mixkit-melodic-gold-price-2000.ogg', name: 'Gold Price'},
    {file: 'mixkit-money-bag-drop-1989.ogg', name: 'Money Bag Drop'},
    {file: 'mixkit-winning-a-coin-video-game-2069.ogg', name: 'Winning Coin Videogame'},
  ]
};

/**
 * Faucet Getters
 * @type {{nextClaimTimer(*): number|number|*, adHostCheck(): (boolean), getSatoshiQuoteClaim(*): {quote: string}, getClaimAmount(*): *, faucetIsSynced(*): boolean, getClaimInfoMessage(*): ({}|undefined)}}
 * @since 2.0.0
 */
const getters = {
  nextClaimTimer(state) {
    return state.next_claim;
  },
  faucetIsSynced(state) {
    return state.loaded;
  },
  isClaimTimerStarted(state) {
    if(state.countdown == null) {
      return false;
    } else {
      return true;
    }
  },
  adHostCheck() {
    if(window.location.host == "swissfaucet.io") {
      return true;
    } else {
      return false;
    }
  },
  getSatoshiQuoteClaim(state) {
    return state.quote;
  },
  getClaimInfoMessage(state) {
    if(state.message !== null) {
      return state.message;
    }
  },
  getClaimAmount(state) {
    return state.amount;
  },
  showClaimCaptcha(state) {
    return state.showCaptcha;
  }
};

/**
 * Faucet Actions
 * @type {{"[FAUCET_CLAIM]"(*): Promise<?>, "[CLAIM_TIMER]"(*): Promise<?>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get Claim Timer
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [CLAIM_TIMER](context) {
    state.loaded = false;
    state.message = null;
    return new Promise((resolve, reject) => {
      ApiService.getAuth("claim").then(({ data }) => {
        state.next_claim = data.next_claim;
        if(data.message !== undefined) {
          if(data.message.type !== undefined) {
            state.message = data.message;
          }
        }
        if(data.captcha !== undefined) {
          if(Number.parseInt(data.captcha) === 1) {
            state.showCaptcha = false;
          }
        }
        if(data.amount !== undefined) {
          state.amount = data.amount;
        }
        state.quote = data.quote;
        state.loaded = true;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Faucet Claim
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [FAUCET_CLAIM](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("claim?v3=true", payload).then(({ data }) => {
        state.loaded = true;
        state.next_claim = parseInt(data.next);
        context.commit(CLAIM_STARTTIMER);
        context.commit(UPDATE_USERBALANCE, { token_balance: data.balance, crypto_balance: data.balance_crypto });
        if(data.daily_claim_count !== undefined) {
          context.commit(UPDATE_DAILY_CLAIM_COUNT, {counter: data.daily_claim_count});
        }
        if(data.xp_info !== undefined) {
          context.commit(UPDATE_USER_XP, data.xp_info);
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

/**
 * Faucet Mutations
 * @type {{"[CLAIM_ENDTIMER]"(): void}}
 * @since 2.0.0
 */
const mutations = {
  [CLAIM_STARTTIMER]() {
    state.countdown = setInterval(() => {
      if(state.next_claim > 0) {
        state.next_claim--;
        document.title = new Date(state.next_claim * 1000).toISOString().substr(14, 5) + ' - Swissfaucet.io - The #1 Crypto Community'
      } else {
        document.title = 'Claim Ready - Swissfaucet.io - The #1 Crypto Community'
        let audio = this.state.user.user.claim_sound;
        if(audio !== undefined) {
          let isAllowed = false;
          // make sure the server did not send any malicious link
          state.soundList.forEach(allowed => {
            if(allowed.file == audio) {
              isAllowed = true;
            }
          });
          if(isAllowed) {
            let alarm = new Audio('/media/sounds/' + audio);
            alarm.play();
          }
        }
        clearInterval(state.countdown);
        state.countdown = null;
      }
    }, 1000);
  },
  [CLAIM_CLEARTIMER]() {
    clearInterval(state.countdown);
    state.countdown = null;
  },
  [CLAIM_UPDATE_TIMER](context, payload) {
    state.next_claim = payload.timer;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};