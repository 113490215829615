const ID_TOKEN_KEY = "id_token";
const REFRESH_TOKEN_KEY = "refresh_token";
const SECRET_TOKEN_KEY = "client_secret";
const CLIENT_ID_KEY = "client_id";
const USER_REF_ID = "user_ref_id";
const SLTAB_NEW_KEY = "sl_new_tab";
const ALARM_INFO_KEY = "fc_alarm_info";
const USER_REF_SRC = "user_ref_src";

export const getFcAlarmInfo = () => {
  return window.localStorage.getItem(ALARM_INFO_KEY);
};

export const saveFcAlarmInfo = minify => {
  window.localStorage.setItem(ALARM_INFO_KEY, minify);
};

export const destroyFcAlarmInfo = () => {
  window.localStorage.removeItem(ALARM_INFO_KEY);
};

export const getSlNewTab = () => {
  return window.localStorage.getItem(SLTAB_NEW_KEY);
};

export const saveSlNewTab = minify => {
  window.localStorage.setItem(SLTAB_NEW_KEY, minify);
};

export const destroySlNewTab = () => {
  window.localStorage.removeItem(SLTAB_NEW_KEY);
};

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const getUserSrc = () => {
  return window.localStorage.getItem(USER_REF_SRC);
};

export const saveUserSrc = minify => {
  window.localStorage.setItem(USER_REF_SRC, minify);
};

export const destroyUserSrc = () => {
  window.localStorage.removeItem(USER_REF_SRC);
};

export const getUserRef = () => {
  return window.localStorage.getItem(USER_REF_ID);
};

export const saveUserRef = minify => {
  window.localStorage.setItem(USER_REF_ID, minify);
};

export const destroyUserRef = () => {
  window.localStorage.removeItem(USER_REF_ID);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getRefreshToken = () => {
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const saveRefreshToken = token => {
  window.localStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const destroyRefreshToken = () => {
  window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const getClientSecret = () => {
  return window.localStorage.getItem(SECRET_TOKEN_KEY);
};

export const saveClientSecret = token => {
  window.localStorage.setItem(SECRET_TOKEN_KEY, token);
};

export const destroyClientSecret = () => {
  window.localStorage.removeItem(SECRET_TOKEN_KEY);
};

export const getClientID = () => {
  return window.localStorage.getItem(CLIENT_ID_KEY);
};

export const saveClientID = token => {
  window.localStorage.setItem(CLIENT_ID_KEY, token);
};

export const destroyClientID = () => {
  window.localStorage.removeItem(CLIENT_ID_KEY);
};

export default {
  getFcAlarmInfo,
  saveFcAlarmInfo,
  destroyFcAlarmInfo,
  getSlNewTab,
  saveSlNewTab,
  destroySlNewTab,
  getToken,
  saveToken,
  destroyToken,
  getRefreshToken,
  saveRefreshToken,
  destroyRefreshToken,
  getClientSecret,
  saveClientSecret,
  destroyClientSecret,
  getClientID,
  saveClientID,
  destroyClientID,
  getUserRef,
  saveUserRef,
  destroyUserRef,
  getUserSrc,
  saveUserSrc,
  destroyUserSrc
};
