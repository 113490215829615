const i18nService = {
  defaultLanguage: "en",

  languages: [
    {
      lang: "en",
      name: "English",
      flag: "226-united-states.svg"
    },
    {
      lang: "de",
      name: "Deutsch",
      flag: "162-germany.svg"
    },
    {
      lang: "es",
      name: "Español",
      flag: "128-spain.svg"
    },
    {
      lang: "it",
      name: "Italiano",
      flag: "013-italy.svg"
    },
    {
      lang: "ru",
      name: "Русский",
      flag: "248-russia.svg"
    },
    {
      lang: "ua",
      name: "Украинский",
      flag: "145-ukraine.svg"
    },
    {
      lang: "jp",
      name: "日本",
      flag: "014-japan.svg"
    }

      /**
    {
      lang: "fr",
      name: "French",
      flag: "195-france.svg"
    } **/
  ],

  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  setActiveLanguage(lang) {
    localStorage.setItem("language", lang);
  },

  /**
   * Get the current active language
   * @returns {string | string}
   */
  getActiveLanguage() {
    return localStorage.getItem("language") || this.defaultLanguage;
  }
};

export default i18nService;
