import Vue from 'vue'
import Vuex from 'vuex'

import auth from "./auth.module"
import tfa from "./2fa.module"
import core from "./core.module"
import achievement from "./achievement.module"
import advertise from "./advertise.module"
import blog from "./blog.module"
import chat from "./chat.module"
import contest from "./contest.module"
import dailyTask from "./dailytask.module"
import dashboard from "./dashboard.module"
import donate from "./donate.module"
import faucet from "./faucet.module"
import feedback from "./feedback.module"
import guild from "./guild.module"
import guildAdmin from "./guildadmin.module"
import hallOfFame from "./halloffame.module"
import mailbox from "./mailbox.module"
import mining from "./mining.module"
import offerWall from "./offerwall.module"
import ptc from "./ptc.module"
import referral from "./referral.module"
import rpsGame from "./rpsgame.module"
import shipsGame from "./shipsgame.module"
import shortLink from "./shortlink.module"
import support from "./support.module"
import token from "./token.module"
import user from "./user.module"
import web from "./web.module"
import withdraw from "./withdraw.module"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    tfa,
    core,
    achievement,
    advertise,
    blog,
    chat,
    contest,
    dailyTask,
    dashboard,
    donate,
    faucet,
    feedback,
    guild,
    guildAdmin,
    hallOfFame,
    mailbox,
    mining,
    offerWall,
    ptc,
    referral,
    rpsGame,
    shipsGame,
    shortLink,
    support,
    token,
    user,
    web,
    withdraw
  }
})
