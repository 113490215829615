import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USERBALANCE} from "@/services/store/user.module";

// Actions
export const GET_TOKENINFO = "getTokenInfo";
export const TOKEN_BUYREQUEST = "placeTokenBuyRequest";
export const TOKEN_WITHDRAW_INTERNAL = "withdrawInternalTokens";

/**
 * Token State
 * @type {{sold: number, token_history: {page: number, items: *[], total_items: number, page_count: number, page_size: number}, tokens_total: number, today_left: number, history: {page: number, items: *[], total_items: number, page_count: number, page_size: number}, token_value: number, my: {pending: number, tokens: number}, totalStaked: number, chartData: {income: *[], cost: *[], data: *[], tokens: *[], percent: *[], profit: *[], labels: *[]}, price: number, last_payment: number, tokens: number, holderBonus: number, nextBonus: number, order: {amount: number, wallet: string, price: number, sendwallet: string, coin: string}}}
 * @since 2.0.0
 */
const state = {
  history: {
    items: [],
    total_items: 0,
    page_size: 0,
    page: 1,
    page_count: 1
  },
  my: {
    tokens: 0,
    pending: 0,
    internal: 0,
  },
  token_history: {
    items: [],
    total_items: 0,
    page_size: 0,
    page: 1,
    page_count: 1
  },
  totalStaked: 0,
  chartData: {
    labels: [],
    data: [],
    percent: [],
    bonus: [],
    cost: [],
    profit: [],
    income: [],
    tokens: []
  },
  tokens: 0,
  sold: 0,
  holderBonus: 0,
  nextBonus: 0,
  nextPercent: 0,
  last_payment: 0,
  token_value: 0,
  tokens_total: 0,
  price: 0,
  price_bch: 0,
  price_ltc: 0,
  price_doge: 0,
  price_zen: 0,
  order: {
    amount: 0,
    coin: "COINS",
    wallet: "",
    sendwallet: "",
    price: 0
  },
  today_left: 0
};

/**
 * Token Getters
 * @type {{getDailyTokenBuyLimit(*): number|number|*, getTokenValue(*): number|number|*, getLinkedTokenCount(*): number|number|*, getTokenCount(*): *, getTokenSold(*): number|number|*, getMyPendingTokenCount(*): *, todayLeft(*): number|number|*, getTokenTotalStaked(*): number|number|*, getTokenHistory(*): [], getLastTokenPayment(*): number|number|*, getMyTokenCount(*): *, tokenCoinPrice(*): number|number|*, getTokenChartData(*): {income: *[], cost: *[], data: *[], tokens: *[], percent: *[], profit: *[], labels: *[]}|{income: [], cost: [], data: [], tokens: [], percent: [], profit: [], labels: []}|*}}
 * @since 2.0.0
 */
const getters = {
  getTokenCount(state) {
    return state.tokens;
  },
  getTokenSold(state) {
    return state.sold;
  },
  getLastTokenPayment(state) {
    return state.last_payment;
  },
  getTokenValue(state) {
    return state.token_value;
  },
  getLinkedTokenCount(state) {
    return state.tokens_total;
  },
  getMyTokenCount(state) {
    return state.my.tokens;
  },
  getMyPendingTokenCount(state) {
    return state.my.pending;
  },
  getMyInternalTokenCount(state) {
    return state.my.internal;
  },
  getDailyTokenBuyLimit(state) {
    return state.today_left;
  },
  getTokenHistory(state) {
    return state.token_history;
  },
  getTokenChartData(state) {
    return state.chartData;
  },
  getTokenTotalStaked(state) {
    return state.totalStaked;
  },
  todayLeft(state) {
    return state.today_left;
  },
  tokenCoinPrice(state) {
    return state.price;
  },
  tokenBchPrice(state) {
    return state.price_bch;
  },
  tokenLtcPrice(state) {
    return state.price_ltc;
  },
  tokenZenPrice(state) {
    return state.price_zen;
  },
  tokenDogePrice(state) {
    return state.price_doge;
  },
  getTokenActiveBonus(state) {
    return state.holderBonus;
  },
  getTokenNextBonus(state) {
    return state.nextBonus;
  },
  getTokenNextPercent(state) {
    return state.nextPercent;
  },
  getMyTokenHistory(state) {
    return state.history;
  }
}

const actions = {
  /**
   * Get Token Info
   *
   * @returns {Promise<unknown>}
   * @since 1.0.0
   */
  [GET_TOKENINFO](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("token?page=" + options.page).then(({data}) => {
        state.my.pending = data._embedded.user.pending;
        state.my.tokens = data._embedded.user.total;
        state.my.internal = data._embedded.token.internal_tokens;
        state.tokens = data._embedded.token.total;
        state.sold = data._embedded.token.sold;
        state.last_payment = data._embedded.token.last_payment;
        state.token_value = data._embedded.token.value;
        state.price = data._embedded.token.price;
        state.price_bch = data._embedded.token.bch_price;
        state.price_ltc = data._embedded.token.ltc_price;
        state.price_zen = data._embedded.token.zen_price;
        state.price_doge = data._embedded.token.doge_price;
        state.tokens_total = data._embedded.token.linked;
        state.token_history = data._embedded.token.history;
        state.holderBonus = data._embedded.token.active_bonus;
        state.nextBonus = data._embedded.token.next_bonus;
        state.nextPercent = data._embedded.token.next_bonus_percent;
        state.history.items = data._embedded.user.history;
        state.history.page_count = data._embedded.user.page_count;
        state.today_left = data._embedded.user.today_left;
        state.totalStaked = data._embedded.history.total;
        state.chartData.data = data._embedded.history.chart.data;
        state.chartData.labels = data._embedded.history.chart.labels;
        state.chartData.cost = data._embedded.history.chart.cost;
        state.chartData.profit = data._embedded.history.chart.profit;
        state.chartData.income = data._embedded.history.chart.income;
        state.chartData.percent = data._embedded.history.chart.percent;
        state.chartData.tokens = data._embedded.history.chart.tokens;
        state.chartData.bonus = data._embedded.history.chart.bonus;

        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Send Token Buy Request
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [TOKEN_BUYREQUEST](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("token", payload).then(({ data }) => {
        if(data.token_balance !== undefined) {
          context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance});
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Send Token Buy Request
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [TOKEN_WITHDRAW_INTERNAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("token", payload).then(({ data }) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  }
};

export default {
  state,
  getters,
  actions,
};
