import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_CREDITBALANCE, UPDATE_USERBALANCE} from "@/services/store/user.module";


// Actions
export const PTC_LIST_ADVERTISE = "loadPTCAdvertiseList";
export const PTC_LOADINFO = "loadPTCAdInfo";
export const PTC_SUBMITAD = "submitPTCAd";
export const PTC_EDITAD = "submitChangedPTCAd";
export const PTC_TOPUP = "topupPTCAd";
export const LOAD_DEPOSITHISTORY = "loadPTCDepositHistory";
export const PTC_SUBMITDEPOSIT = "submitPTCDeposit";
export const PTC_CONFIRMCOINPAYMENT = "confirmCoinPaymentForDeposit";
export const PTC_REMOVE = "removePtcAd";

/**
 * Advertise State
 * @type {{price: {coins: number}, ptc_manage: *[], ptc_stats: {views_total: number, views_delivered_30day: {data: *[], max: number, labels: *[]}}, deposit: {amount: number, currency: string}, ptc_info: {}, deposit_pay: {amount: number, wallet: string, token_balance: number, price: number, currency: string}, deposit_history: {total_items: number, items: *[], page_count: number}}}
 * @since 2.0.0
 */
const state = {
  ptc_manage: [],
  ptc_info: {},
  ptc_stats: {
    views_delivered_30day: {
      labels: [],
      data: [],
      max: 0
    },
    views_total: 0
  },
  price: {
    coins: 0,
  },
  deposit: {
    currency: "",
    amount: 0
  },
  deposit_history: {
    total_items: 0,
    page_count: 0,
    items: []
  },
  deposit_pay: {
    currency: "",
    amount: 0,
    price: 0,
    wallet: "",
    token_balance: 0
  }
};

/**
 * Advertise Getters
 * @type {{ptcGetManageList(*): []|*, getDepositPayInfo(*): {amount: number, wallet: string, token_balance: number, price: number, currency: string}, getCreditsCoinPrice(*): number|*, getPTCAdInfo(*): {}|*, getPTCDetailStats(*): {data: [], max: number, labels: []}|*, getDepositBuyInfo(*): {amount: number, currency: string}}}
 * @since 2.0.0
 */
const getters = {
  ptcGetManageList(state) {
    return state.ptc_manage;
  },
  getPTCDetailStats(state) {
    return state.ptc_stats;
  },
  getPTCAdInfo(state) {
    return state.ptc_info;
  },
  getCreditsCoinPrice(state) {
    return state.price.coins;
  },
  getDepositBuyInfo(state) {
    return state.deposit;
  },
  getDepositPayInfo(state) {
    return state.deposit_pay;
  },
  getPtcDepositHistory(state) {
    return state.deposit_history;
  }
};

/**
 * Advertise Actions
 * @type {{"[PTC_LOADINFO]"(*, *): Promise<*>, "[PTC_CONFIRMCOINPAYMENT]"(*, *=): Promise<*>, "[PTC_SUBMITAD]"(*, *=): Promise<*>, "[LOAD_DEPOSITHISTORY]"(*, *): Promise<*>, "[PTC_SUBMITDEPOSIT]"(*, *=): Promise<*>, "[PTC_LIST_ADVERTISE]"(*, *): Promise<*>, "[PTC_EDITAD]"(*, *=): Promise<*>, "[PTC_TOPUP]"(*, *=): Promise<*>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Get PTC Ads (View)
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_LIST_ADVERTISE](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("ptc?page=" + options.page).then(({data}) => {
        state.ptc_manage = data.ptc_my;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Get PTC Ad Info (Advertiser)
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_LOADINFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("ptc/" + payload.id).then(({data}) => {
        state.ptc_info = data.ptc;
        state.ptc_stats = data.ptc.chart;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Submit PTC Ad for Approval
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_SUBMITAD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("ptc", payload).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Submit PTC Ad for Approval
   *
   * @returns {Promise<unknown>}
   * @since 1.0.0
   */
  [PTC_EDITAD](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("ptc/" + payload.id, payload).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Confirm Coin Payment for Deposit
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_TOPUP](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("ptcmanager", payload).then(({data}) => {
        if (data.credit_balance !== undefined) {
          context.commit(UPDATE_CREDITBALANCE, {credit_balance: data.credit_balance});
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load PTC Deposit History
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_DEPOSITHISTORY](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("ptcdeposit?page=" + payload.page).then(({data}) => {
        state.deposit_history = data.deposit;
        state.price.coins = data.price.coins;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load PTC Deposit History
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_SUBMITDEPOSIT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("ptcdeposit", payload).then(({data}) => {
        state.deposit_pay.currency = data.currency;
        state.deposit_pay.amount = data.amount;
        state.deposit_pay.wallet = data.wallet;
        state.deposit_pay.price = data.price;
        state.deposit_pay.token_balance = data.token_balance;

        if (data.credit_balance !== undefined) {
          context.commit(UPDATE_CREDITBALANCE, {credit_balance: data.credit_balance});
        }
        if (data.token_balance !== undefined) {
          context.commit(UPDATE_USERBALANCE, {token_balance: data.token_balance});
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Confirm Coin Payment for Deposit
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_CONFIRMCOINPAYMENT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("ptcdeposit", payload).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Confirm Coin Payment for Deposit
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [PTC_REMOVE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.deleteNoCaptcha("ptc/" + payload.id).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
};

export default {
  state,
  actions,
  getters
};