import ApiService from "@/services/api.service";
import {REFRESH_TOKEN, SET_ERROR} from "@/services/store/auth.module";
import {getUserRef, getUserSrc, saveUserRef, saveUserSrc} from "@/services/jwt.service";
import {CLAIM_UPDATE_TIMER} from "@/services/store/faucet.module";
import {UPDATE_DAILY_CLAIM_COUNT} from "@/services/store/dailytask.module";

// Actions
export const USER_INFO = "getUserInfo";
export const LOAD_MY_TRANSACTIONS = "getUserPersonalTransactionHistory";
export const UPDATE_PERSONAL_INFO = "updateAccountPersonalInfo";
export const GACHA_CONNECT = "linkGachaMinerAccount";

// Mutations
export const UPDATE_USERBALANCE = "updateUserBalance";
export const UPDATE_CREDITBALANCE = "updateUserCreditBalance";
export const UPDATE_USER_GUILD = "updateUserGuildInfo";
export const UPDATE_INBOX_COUNTER = "updateUserInboxCounter";
export const USER_SETREFERRAL = "setUserReferralID";
export const USER_SETGUILDJOIN = "setUserGuildJoin";
export const UPDATE_USER_XP = "updateUserXPInfo";

/**
 * User State
 * @type {{referral: number, my_activity: {feed: *[], totalItems: number, pageCount: number, pageSize: number}, user: {}, guildJoin: {id: number}, refSource: null}}
 * @since 2.0.0
 */
const state = {
  user: {},
  referral: 0,
  refSource: null,
  guildJoin: {
    id: 0
  },
  my_activity: {
    feed: [],
    totalItems: 0,
    pageCount: 0,
    pageSize: 0
  },
  forceUpdate: false,
};

/**
 * User Getters
 * @type {{currentClientVersion(*): string|string|*, currentUser(*): any}}
 * @since 2.0.0
 */
const getters = {
  currentUser(state) {
    return state.user;
  },
  getCurrentYear(state) {
    return new Date(state.user.servertime).getFullYear();
  },
  userIsVerified(state) {
    if(state.user.verified !== undefined) {
      if(state.user.verified == 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
  getUserActivityFeed(state) {
    return state.my_activity;
  },
  getReferralID(state) {
    let cachedRef = getUserRef();
    if(cachedRef !== undefined) {
      if(cachedRef != 0 && cachedRef != "") {
        return cachedRef;
      }
    }

    return state.referral;
  },
  getReferralSource(state) {
    let cachedSrc = getUserSrc();
    if(cachedSrc !== undefined) {
      if(cachedSrc != 0 && cachedSrc != "") {
        return cachedSrc;
      }
    }

    return state.refSource;
  },
  getGuildQuickInfo(state) {
    return state.guildJoin;
  },
  isRequiredUpdateAvailable(state) {
    return state.forceUpdate;
  }
}

/**
 * User Actions
 * @type {{"[USER_INFO]"(*): Promise<unknown>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * Load Basic User Information
   * @param context
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [USER_INFO](context) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.getAuth("user?v=" + this.state.auth.client_version)
      .then(({data}) => {
        state.user = data
        if(data.claim_timer !== undefined) {
          context.commit(CLAIM_UPDATE_TIMER, {timer: data.claim_timer});
        }
        if(data.force_update !== undefined) {
          if(data.force_update == 1) {
            state.forceUpdate = true;
          }
        }
        if(data.daily_claim_count !== undefined) {
          context.commit(UPDATE_DAILY_CLAIM_COUNT, {counter: data.daily_claim_count});
        }
        resolve(data);
      })
      .catch(({response}) => {
        if(response.status !== undefined) {
          if(response.status == 401) {
            context.commit(REFRESH_TOKEN);
          }
        }
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Load User Transaction History
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_MY_TRANSACTIONS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("transactions?page="+payload.page, payload).then(response => {
        state.my_activity.feed = response.data.transactions;
        state.my_activity.totalItems = response.data.total_items;
        state.my_activity.pageCount = response.data.page_count;
        state.my_activity.pageSize = response.data.page_size;
        resolve(response);
      }).catch(({response}) => {
        if(response.status !== undefined) {
          if(response.status == 401) {
            context.commit(REFRESH_TOKEN);
          }
        }
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Update Profile Info
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [UPDATE_PERSONAL_INFO](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("user/update", payload).then((response) => {
        state.user = response.data.user;
        resolve(response);
      }).catch(({response}) => {
        if(response.status !== undefined) {
          if(response.status == 401) {
            context.commit(REFRESH_TOKEN);
          }
        }
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Connect Gachaminer Account
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GACHA_CONNECT](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.postCustom("auth", payload, 'https://api.beta.gachaminer.io').then(response => {
        resolve(response);
      }).catch(({response}) => {
        if(response.status !== undefined) {
          if(response.status == 401) {
            context.commit(REFRESH_TOKEN);
          }
        }
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
}

/**
 * User Mutations
 * @type {{}}
 * @since 2.0.0
 */
const mutations = {
  [UPDATE_USERBALANCE](state, update) {
    state.user.token_balance = update.token_balance;
    if(update.crypto_balance !== undefined) {
      state.user.crypto_balance = Number(update.crypto_balance);
    }
  },
  [UPDATE_CREDITBALANCE](state, update) {
    if(update.credit_balance !== undefined) {
      state.user.credit_balance = update.credit_balance;
    }
  },
  [UPDATE_USER_XP](state, update) {
    if(update.xp_level !== undefined) {
      state.user.xp_level = update.xp_level;
    }
    if(update.xp_percent !== undefined) {
      state.user.xp_percent = update.xp_percent;
    }
  },
  [UPDATE_USER_GUILD](state, update) {
    state.user.guild = update.guild;
  },
  [UPDATE_INBOX_COUNTER](context, payload) {
    state.user.inbox_count = payload.amount;
  },
  [USER_SETREFERRAL](context, payload) {
    state.referral = payload.referral;
    if(payload.source !== undefined) {
      if(payload.source !== null) {
        state.refSource = payload.source;
        saveUserSrc(payload.source);
      }
    }
    saveUserRef(payload.referral);
  },
  [USER_SETGUILDJOIN](context, payload) {
    state.guildJoin = payload;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};