// Mutations
export const SET_MOBILE = "setMobileMode";
export const SHUFFLE_MAIN_AD_SLOT ="shuffleMainAdSlot";

/**
 * Core State
 * @type {{isMobile: boolean}}
 * @since 2.0.0
 */
const state = {
  isMobile: false,
  mainAdSlot: null,
  mainAdProviders: ["aads", "bitmedia", "ccads", "galaksion"],
  reviveId: "4725126cf33877155480cd649b7ad6f8"
};

/**
 * Core Getters
 * @type {{isMobile(*): *}}
 * @since 2.0.0
 */
const getters = {
  isMobile(state) {
    return state.isMobile
  },
  getActiveMainAdSlot(state) {
    return state.mainAdSlot;
  },
  getReviveAdId(state) {
    return state.reviveId;
  }
};

/**
 * Core Actions
 * @type {{}}
 * @since 2.0.0
 */
const actions = {

};

/**
 * Core Mutations
 * @type {{"[SET_MOBILE]"(*, *=): void}}
 * @since 2.0.0
 */
const mutations = {
  [SET_MOBILE](state, isMobile) {
    if(isMobile) {
      state.isMobile = true;
    } else {
      state.isMobile = false;
    }
  },
  [SHUFFLE_MAIN_AD_SLOT](state) {
    state.mainAdSlot = state.mainAdProviders[Math.floor(Math.random() * state.mainAdProviders.length)];
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};