import ApiService from "@/services/api.service";
import {SET_ERROR} from "@/services/store/auth.module";
import {UPDATE_USER_GUILD, USER_SETGUILDJOIN} from "@/services/store/user.module";

// Actions
export const GUILD_LIST = "loadGuildList";
export const GUILD_JOIN = "sendGuildJoinRequest";
export const GUILD_CREATE = "createGuild";
export const GUILD_INFO = "getGuildInfo";
export const GUILD_LEAVE = "sendGuildLeaveRequest";
export const LOAD_GUILD_TRANSACTIONS = "getGuildTransactionHistory";
export const GUILD_STATS = "loadGuildMemberStats";
export const GUILD_QUICKINFO = "loadGuildQuickInfo";
export const GUILD_WEEKLY_TASKS = "loadGuildWeekyTasks";

export const GUILDNEWS_CREATE = "createGuildNews";
export const GUILDNEWS_LOAD = "loadGuildNews";

export const GUILDBANK_DEPOSIT = "guildBankDeposit";
export const GUILDBANK_WITHDRAW = "guildBankWithdraw";
export const GUILDBANK_INFO = "getGuildBankInfo";

// Mutations
export const GUILD_UPDATEICON = "updateGuildIcon";
export const GUILD_UPDATEBALANCE = "updateGuildTokenBalance";
export const GUILD_BROWSE_FILTER = "setGuildBrowseFocusFilter";
export const GUILD_UPDATEINFO = "updateGuildPartialInfo";
export const GUILD_UPDATE_WITHDRAW_TIMER = "updateWithdrawTimer";

/**
 * Guild State
 * @type {{focusFilter: number, guilds: *[], guild_activity: {feed: *[], totalItems: number, pageCount: number, pageSize: number}, total_items: number, guild: {token_balance: number, icon: string, focus: {f: number, of: number, lt: number, sl: number, m: number}, xp_percent: number, welcome_message: string, chat_banlist: *[], my_rank: {permissions: *[], name: string, id: number}, ranks: *[], members: *[], icon_tmp: string, name: string, total_requests: number, xp_level: number, id: number}, bank: {withdrawTimer: number, withdrawLimit: number, transactions: {page: number, transactions: *[], total_items: number, page_count: number, page_size: number}}, recentNews: *[], stats: {total: {miner_cpu: *[], offerwall: *[], offersmall: *[], faucet: *[], miner_gpu: *[], dailys: *[], shortlink: *[]}, week: {miner_cpu: *[], offerwall: *[], offersmall: *[], faucet: *[], miner_gpu: *[], dailys: *[], shortlink: *[]}, month: {miner_cpu: *[], offerwall: *[], offersmall: *[], faucet: *[], miner_gpu: *[], dailys: *[], shortlink: *[]}}, banList: *[], sizeFilter: number, focusList: *[], page: number, langFilter: number, page_count: number}}
 * @since 2.0.0
 */
const state = {
  guilds: [],
  total_items: 0,
  page_count: 0,
  page: 1,
  banList: [],
  recentNews: [],
  guild_activity: {
    feed: [],
    totalItems: 0,
    pageCount: 0,
    pageSize: 0
  },
  guild: {
    total_requests:0,
    token_balance: 0,
    xp_level: 1,
    chat_banlist: [],
    xp_percent: 0,
    id: 0,
    icon: "",
    icon_tmp: "",
    members: [],
    welcome_message: "",
    name: "Guild",
    my_rank: {
      id: 9,
      name: "Member",
      permissions: []
    },
    focus: {
      f: 0,
      sl: 0,
      of: 0,
      lt: 0,
      m: 0
    },
    ranks: []
  },
  bank: {
    transactions: {
      total_items: 0,
      page_count: 0,
      page: 1,
      page_size: 0,
      transactions: [],
    },
    withdrawLimit: 0,
    withdrawTimer: 0,
  },
  focusFilter: 0,
  sizeFilter: 0,
  langFilter: 0,
  focusList: [],
  stats: {
    month: {
      shortlink: [],
      faucet: [],
      offerwall: [],
      offermed: [],
      offersmall: [],
      offertiny: [],
      dailys: [],
      miner_cpu: [],
      miner_gpu: []
    },
    week: {
      shortlink: [],
      faucet: [],
      dailys: [],
      offerwall: [],
      offermed: [],
      offersmall: [],
      offertiny: [],
      miner_cpu: [],
      miner_gpu: []
    },
    total: {
      shortlink: [],
      faucet: [],
      offerwall: [],
      offermed: [],
      offersmall: [],
      offertiny: [],
      dailys: [],
      miner_cpu: [],
      miner_gpu: []
    }
  }
};

/**
 * Guild Getters
 * @type {{getGuildBankHistory(*): [], guildBankInventory(*): [], getGuildList(*): []|*[]|*, getOpenJoinRequestCount(*): number, getGuildFocusList(*): []|*[]|*, guildData(*): {token_balance: number, icon: string, focus: {f: number, of: number, lt: number, sl: number, m: number}, xp_percent: number, welcome_message: string, chat_banlist: *[], my_rank: {permissions: *[], name: string, id: number}, ranks: *[], members: *[], icon_tmp: string, name: string, total_requests: number, xp_level: number, id: number}|{token_balance: number, icon: string, focus: {f: number, of: number, lt: number, sl: number, m: number}, xp_percent: number, welcome_message: string, chat_banlist: [], my_rank: {permissions: [], name: string, id: number}, ranks: [], members: [], icon_tmp: string, name: string, total_requests: number, xp_level: number, id: number}|*, guildChatUserBanList(*): []|*[]|*, isGuildMaster(*): (boolean), getOpenJoinRequestList(*): [], getGuildBankHistoryPageCount(*): number, guildPageCount(*): number, getGuildMemberCount(*): number, getGuildJoinCount(*): number, getMyDailyGBLimit(*): number, guildTotalCount(*): number}}
 * @since 2.0.0
 */
const getters = {
  guildData(state) {
    return state.guild;
  },
  getGuildList(state) {
    return state.guilds;
  },
  guildTotalCount(state) {
    return state.total_items;
  },
  guildPageCount(state) {
    return state.page_count;
  },
  isGuildMaster(state) {
    if(state.guild.my_rank.id == 0) {
      return true;
    } else {
      return false;
    }
  },
  guildBankInventory(state) {
    return state.bank.inventory;
  },
  getGuildMemberCount(state) {
    return state.total_items;
  },
  getGuildJoinCount(state) {
    return state.guild.total_requests;
  },
  getGuildBankHistory(state) {
    return state.bank.transactions;
  },
  guildChatUserBanList(state) {
    return state.banList;
  },
  getMyDailyGBLimit(state) {
    return state.bank.withdrawLimit;
  },
  getMyDailyGBTimer(state) {
    return state.bank.withdrawTimer;
  },
  getGuildFocusList(state) {
    return state.focusList;
  },
  getGuildNews(state) {
    return state.recentNews;
  },
  getGuildActivityFeed(state) {
    return state.guild_activity;
  },
  getGuildStats(state) {
    return state.stats;
  }
};

/**
 * Guild Actions
 * @type {{"[GUILD_LIST]"(*): Promise<*>, "[GUILD_CREATE]"(*, *=): Promise<*>, "[GUILD_INFO]"(*, *): Promise<*>, "[GUILD_JOIN]"(*, *=): Promise<*>, "[GUILD_LEAVE]"(*, *=): Promise<*>}}
 * @since 2.0.0
 */
const actions = {
  /**
   * List existing Guilds for Browsing
   * @param context
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILD_LIST](context, payload) {
    return new Promise((resolve, reject) => {
      let url = "guild?v2=true&page=" + payload.page;
      if (payload.focusFilter != 0) {
        url += "&focus=" + payload.focusFilter;
      }
      if (payload.sizeFilter != 0) {
        url += "&size=" + payload.sizeFilter;
      }
      if (payload.langFilter != 0) {
        url += "&lang=" + payload.langFilter;
      }
      ApiService.getAuth(url).then(({data}) => {
        state.guilds = data._embedded.guild;
        state.total_items = data.total_items;
        state.page_count = data.page_count;
        state.page = Number(data.page);
        state.focusList = data.focus;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Send Guild Join Request
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILD_JOIN](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.put("guild/join", guild).then(({data}) => {
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Send Guild Leave Request
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILD_LEAVE](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.delete("guild/leave", guild).then(({data}) => {
        state.guild = {};
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Create a New Guild
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILD_CREATE](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.post("guild/create", guild).then(({data}) => {
        context.commit(UPDATE_USER_GUILD, {
          guild: data.guild
        });
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Get Guild Info
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILD_INFO](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guild/" + guild.id + "?page=" + guild.page).then(
          ({ data }) => {
            state.guild = data.guild;
            state.total_items = data.guild.total_members;
            state.guild.total_requests = data.guild.total_requests;
            state.page_count = data.guild.page_count;
            state.page = Number(data.guild.page);
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  [GUILD_WEEKLY_TASKS](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guild/" + guild.id + "?weekly=1").then(
          ({ data }) => {
            resolve(data);
          }
      ).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  [GUILD_QUICKINFO](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.post("oauth", {
        client_id: "vue2web",
        client_secret: "PNbeGzyaKrKBETJi9YT7LSC9",
        grant_type: "client_credentials"
      }).then(response => {
        ApiService.get("guild/" + guild.link + "?mode=quick", "",{
          headers: {
            Authorization: "Bearer " + response.data.access_token
          }
        }).then(
            ({ data }) => {
              context.commit(USER_SETGUILDJOIN, data.guild);
              resolve(data);
            }
        ).catch(({response}) => {
          context.commit(SET_ERROR, response.data);
          reject();
        });
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  //
  /**
   * Create a New Guild News Post
   * @param context
   * @param guild
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDNEWS_CREATE](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.post("guildnews", guild).then(({ data }) => {
        data.status = 200;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  /**
   * Load Guild News
   * @param context
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [GUILDNEWS_LOAD](context) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guildnews").then(({ data }) => {
        if(data._embedded !== undefined) {
          if(data._embedded.news !== undefined) {
            state.recentNews = data._embedded.news[0];
          }
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load Guild Activity Feed
   * @param context
   * @param payload
   * @returns {Promise<unknown>}
   * @since 2.0.0
   */
  [LOAD_GUILD_TRANSACTIONS](context, payload){
    return new Promise((resolve, reject) => {
      ApiService.post("transactions?page="+payload.page+"&filter=guild", payload).then(response => {
        state.guild_activity.feed = response.data.transactions;
        state.guild_activity.totalItems = response.data.total_items;
        state.guild_activity.pageCount = response.data.page_count;
        state.guild_activity.pageSize = response.data.page_size;
        resolve(response);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  /**
   * Load Guild Bank Transactions
   * @param context
   * @param options
   * @returns {Promise<unknown>}
   */
  [GUILDBANK_INFO](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guildbank?page="+options.page).then(({ data }) => {
        state.bank.transactions.transactions = data.transactions;
        state.bank.transactions.page = Number(data.page);
        state.bank.transactions.page_size = Number(data.page_size);
        state.bank.transactions.page_count = Number(data.page_count);
        state.bank.transactions.total_items = Number(data.total_items);
        state.bank.withdrawLimit = Number(data.withdraw_limit);
        state.bank.withdrawTimer = data.withdraw_timer;
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },

  [GUILDBANK_DEPOSIT](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.put("guildbank", guild).then(({ data }) => {
        if(data.transactions !== undefined) {
          state.bank.transactions.transactions = data.transactions.transactions;
          state.bank.transactions.page = Number(data.transactions.page);
          state.bank.transactions.page_size = Number(data.transactions.page_size);
          state.bank.transactions.page_count = Number(data.transactions.page_count);
          state.bank.transactions.total_items = Number(data.transactions.total_items);
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  [GUILDBANK_WITHDRAW](context, guild) {
    return new Promise((resolve, reject) => {
      ApiService.post("guildbank", guild).then(({ data }) => {
        if(data.transactions !== undefined) {
          state.bank.transactions.transactions = data.transactions.transactions;
          state.bank.transactions.page = Number(data.transactions.page);
          state.bank.transactions.page_size = Number(data.transactions.page_size);
          state.bank.transactions.page_count = Number(data.transactions.page_count);
          state.bank.transactions.total_items = Number(data.transactions.total_items);
        }
        if(data.withdraw_timer !== undefined) {
          state.bank.withdrawTimer = data.withdraw_timer;
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
  [GUILD_STATS](context, options) {
    return new Promise((resolve, reject) => {
      ApiService.getAuth("guild-stats?mode="+options.mode).then(({ data }) => {
        if(options.mode == 'month' || options.mode == 'lastmonth') {
          state.stats.month.offerwall = data.offerwall;
          state.stats.month.faucet = data.faucet;
          state.stats.month.offersmall = data.offersmall;
          state.stats.month.offertiny = data.offertiny;
          state.stats.month.offermed = data.offermed;
          state.stats.month.miner_cpu = data.miner_cpu;
          state.stats.month.miner_gpu = data.miner_gpu;
          state.stats.month.dailys = data.dailys;
          state.stats.month.shortlink = data.shortlink;
        }
        if(options.mode == 'week') {
          state.stats.week.offerwall = data.offerwall;
          state.stats.week.offersmall = data.offersmall;
          state.stats.week.offertiny = data.offertiny;
          state.stats.week.offermed = data.offermed;
          state.stats.week.miner_cpu = data.miner_cpu;
          state.stats.week.miner_gpu = data.miner_gpu;
          state.stats.week.faucet = data.faucet;
          state.stats.week.dailys = data.dailys;
          state.stats.week.shortlink = data.shortlink;
        }
        if(options.mode == 'total') {
          state.stats.total.offerwall = data.offerwall;
          state.stats.total.faucet = data.faucet;
          state.stats.total.offersmall = data.offersmall;
          state.stats.total.offertiny = data.offertiny;
          state.stats.total.offermed = data.offermed;
          state.stats.total.miner_cpu = data.miner_cpu;
          state.stats.total.miner_gpu = data.miner_gpu;
          state.stats.total.shortlink = data.shortlink;
          state.stats.total.dailys = data.dailys;
        }
        resolve(data);
      }).catch(({response}) => {
        context.commit(SET_ERROR, response.data);
        reject();
      });
    });
  },
};

/**
 * Guild Mutations
 * @type {{"[GUILD_UPDATEBALANCE]"(*, *): void, "[GUILD_BROWSE_FILTER]"(*, *): void, "[GUILD_UPDATEICON]"(*, *): void}}
 * @since 2.0.0
 */
const mutations = {
  [GUILD_UPDATEICON](context, payload) {
    state.guild.emblem_shield = payload.emblem_shield;
    state.guild.emblem_icon = payload.emblem_icon;
  },

  [GUILD_UPDATEBALANCE](context, payload) {
    state.guild.token_balance = payload.token_balance;
  },

  [GUILD_UPDATEINFO](context, payload) {
    if(payload.name !== undefined && payload.name != "") {
      state.guild.name = payload.name;
    }
    if(payload.link !== undefined && payload.link != "") {
      state.guild.link = payload.link;
    }
    if(payload.description !== undefined && payload.description != "") {
      state.guild.description = payload.description;
    }
    if(payload.welcome_message !== undefined && payload.welcome_message != "") {
      state.guild.welcome_message = payload.welcome_message;
    }
    if(payload.main_language !== undefined && payload.main_language != "") {
      state.guild.language = payload.main_language;
    }
    if(payload.focus !== undefined) {
      state.guild.focus = JSON.parse(payload.focus);
    }
    if(payload.total_requests !== undefined) {
      state.guild.total_requests = payload.total_requests;
    }
    if(payload.emblem_shield !== undefined) {
      state.guild.emblem_shield = payload.emblem_shield;
      state.guild.emblem_icon = payload.emblem_icon;
    }
    if(payload.ranks !== undefined) {
      state.guild.ranks = payload.ranks;
    }
    if(payload.members !== undefined) {
      state.guild.members = payload.members;
    }
  },

  [GUILD_BROWSE_FILTER](context, payload) {
    if(payload.focus !== undefined) {
      state.focusFilter = payload.focus;
    }
    if(payload.size !== undefined) {
      state.sizeFilter = payload.size;
    }
    if(payload.lang !== undefined) {
      state.langFilter = payload.lang;
    }
  },

  [GUILD_UPDATE_WITHDRAW_TIMER](context, payload) {
    state.bank.withdrawTimer = payload.timer;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};